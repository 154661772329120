import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
import ChecklistResourcesSection from "../../../components/ChecklistResourcesSection/ChecklistResourcesSection.jsx";
import { checklists } from "../../../layout/Constant.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ChecklistResourcesSection figmaUrl={checklists.componentChips.figmaUrl.android} codeUrl={checklists.componentChips.codeUrl.android} checklists={checklists.componentChips.checklists} mdxType="ChecklistResourcesSection" />
    <div className="divi" />
    <p>{`Chips allow users to enter information, make selections, filter content, or trigger actions.`}</p>
    <p>{`While buttons are expected to appear consistently and with familiar calls to action, chips should appear dynamically as a group of multiple interactive elements.`}</p>
    <div {...{
      "className": "line",
      "style": {
        "backgroundColor": "white"
      }
    }}>{`
  `}<div parentName="div" {...{
        "className": "item-one",
        "align": "center"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/chips/chips-android-1.gif",
            "width": 200,
            "height": 410,
            "align": "center",
            "resize-mode": "contain",
            "alt": "chips usage"
          }}></img>{`
    `}</div>{`
  `}</div>
    </div>
    <div className="divi" />
    <h2>{`Variants`}</h2>
    <p>{`Legion has two theme variants of chips :`}</p>
    <ol>
      <li parentName="ol">{`Primary`}</li>
      <li parentName="ol">{`Secondary`}</li>
    </ol>
    <p>{`and also have two size variant of chips :`}</p>
    <ol>
      <li parentName="ol">{`Large`}</li>
      <li parentName="ol">{`Small`}</li>
    </ol>
    <h2>{`Usage`}</h2>
    <h3>{`Primary`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`    var selected by remember { mutableStateOf(false) }

    LgnChip(
        selected = selected,
        onClick = { selected = !selected },
        label = { Text(text = "Primary") }
    )
`}</code></pre>
    <h3>{`Secondary`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`    var selected by remember { mutableStateOf(false) }

    LgnChip(
        selected = selected,
        onClick = { selected = !selected },
        label = { Text(text = "Secondary") },
        colors = LgnChipDefaults.SolidTheme.secondary()
    )
`}</code></pre>
    <h3>{`Large`}</h3>
    <h4>{`Static in xml`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`    var selected by remember { mutableStateOf(false) }

    LgnChip(
        selected = selected,
        onClick = { selected = !selected },
        label = { Text(text = "Large") },
        size = LgnChipSize.Large
    )
`}</code></pre>
    <h3>{`Small`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`    var selected by remember { mutableStateOf(false) }

    LgnChip(
        selected = selected,
        onClick = { selected = !selected },
        label = { Text(text = "Small") },
        size = LgnChipSize.Small
    )
`}</code></pre>
    <div className="divi" />
    <h2>{`Attributes`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameters`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`label`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set label of chip`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`selected`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Boolean`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set state of chip`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`onClick`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Function`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`called when this chip is clicked`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`colors`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`LgnChipColors`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`[LgnChipColors]`}{` that will be used to resolve the colors used for this chip in different states. See `}{`[LgnChipDefaults.solidColors]`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`enabled`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Boolean`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set whether the button can be pressed or not`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`iconLeft`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Composable`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set left icon of chip`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`iconRight`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Composable`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set Right icon of chip`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`avatar`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Composable`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`optional image to be displayed on the left side of the chip`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`shape`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Shape`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`defines the shape of this chip’s container`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`border`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`LgnChipBorder`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`[LgnChipBorder]`}{` the border to draw around the container of this chip. Pass `}<inlineCode parentName="td">{`null`}</inlineCode>{` for no border. See `}{`[LgnChipDefaults.border]`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`size`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`LgnChipSize`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set size of chip by LgnChipSize.Small`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`modifier`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Modifier`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`[Modifier]`}{` to be applied to the chip`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`interactionSource`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`MutableInteractionSource`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`[MutableInteractionSource]`}{` representing the source of the interactions`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      